import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./serviceLetter.css";
import ServiceLetter from "./serviceLetter";
import { useReactToPrint } from "react-to-print";

const ServiceLetterForm = () => {
  const [formData, setFormData] = useState({
    courtesyTitle: "",
    name: "",
    pronoun: "",  // Changed from Pronoun to pronoun to be consistent
    university: "",
    jobType: "",
    duration: "",
    startDate: "",
    endDate: "",
    work1: "",
    work2: "",
    work3: "",
    work4: "",
    work5: "",
  });

  const letterRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => letterRef.current,
    documentTitle: formData.name, // This will set the downloaded file name to the name in formData
    onAfterPrint: () => alert("Download Complete!"),
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="courtesyTitle">Title:</label>
          <select
            name="courtesyTitle"
            value={formData.courtesyTitle}
            onChange={handleChange}
            className="form-control"
            id="courtesyTitle"
          >
            <option value="">Select</option>
            <option value="Mr.">Mr.</option>
            <option value="Miss">Miss</option>
            <option value="Mrs.">Mrs.</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-control"
            id="name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="pronoun">Pronoun:</label> {/* Changed id and name from 'Pronoun' to 'pronoun' */}
          <select
            name="pronoun"
            value={formData.pronoun}
            onChange={handleChange}
            className="form-control"
            id="pronoun"
          >
            <option value="">Select</option>
            <option value="her">her</option>
            <option value="his">his</option>
            <option value="your">your</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="university">University:</label>
          <input
            type="text"
            name="university"
            value={formData.university}
            onChange={handleChange}
            className="form-control"
            id="university"
          />
        </div>
        <div className="form-group">
          <label htmlFor="jobType">Job Type:</label>
          <input
            type="text"
            name="jobType"
            value={formData.jobType}
            onChange={handleChange}
            className="form-control"
            id="jobType"
          />
        </div>
        <div className="form-group">
          <label htmlFor="duration">Duration:</label>
          <input
            type="text"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            className="form-control"
            id="duration"
          />
        </div>
        <div className="form-group">
          <label htmlFor="startDate">Start Date:</label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            className="form-control"
            id="startDate"
          />
        </div>
        <div className="form-group">
          <label htmlFor="endDate">End Date:</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            className="form-control"
            id="endDate"
          />
        </div>
        <div className="form-group">
          <label htmlFor="work1">What are the works he did previously:</label>
          <input
            type="text"
            name="work1"
            value={formData.work1}
            onChange={handleChange}
            className="form-control"
            id="work1"
          />
          <input
            type="text"
            name="work2"
            value={formData.work2}
            onChange={handleChange}
            className="form-control"
            id="work2"
          />
          <input
            type="text"
            name="work3"
            value={formData.work3}
            onChange={handleChange}
            className="form-control"
            id="work3"
          />
          <input
            type="text"
            name="work4"
            value={formData.work4}
            onChange={handleChange}
            className="form-control"
            id="work4"
          />
          <input
            type="text"
            name="work5"
            value={formData.work5}
            onChange={handleChange}
            className="form-control"
            id="work5"
          />
        </div>
        <button type="submit" className="btn btn-primary mt-3">
          Generate Letter
        </button>
      </form>
      {formData.name && (
        <div className="mt-5">
          <h2>Generated Service Letter</h2>
          <ServiceLetter ref={letterRef} formData={formData} />
          <button className="btn btn-success mt-3" onClick={handlePrint}>
            Download Letter
          </button>
        </div>
      )}
    </div>
  );
};

export default ServiceLetterForm;
