import React, { useState, useRef } from "react";
import ServiceLetterForm from "../src/serviceLetterForm";
import ServiceLetter from "./serviceLetter";
import ReactToPrint from "react-to-print";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [formData, setFormData] = useState(null);
  const componentRef = useRef();

  const generatePDF = async () => {
    const input = componentRef.current;
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 0, 0);
    pdf.save("{formData.name}.pdf");
  };

  return (
    <div className="App">
      <h1>Service Letter Generator</h1>
      <ServiceLetterForm onSubmit={setFormData} />
      {formData && (
        <div>
          <ServiceLetter formData={formData} ref={componentRef} />
          <ReactToPrint
            trigger={() => <button>Print this out!</button>}
            content={() => componentRef.current}
          />
          <button onClick={generatePDF}>Download as PDF</button>
        </div>
      )}
    </div>
  );
}

export default App;
