import React, { forwardRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./serviceLetter.css";

const ServiceLetter = forwardRef(({ formData }, ref) => {
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div ref={ref} className="service-letter mx-auto">
      <div className="header mb-5">
        <div className="row">
          <div className="col-md-6">
            <img
              src="company-logo.png"
              alt="Company Logo"
              className="img-fluid"
            />
          </div>
        </div>
        <div>
          <img
            src="top-corner-image.png"
            alt="Top Corner"
            className="corner-image top-corner"
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p>{formattedDate}</p>
      <p>To Whom It May Concern,</p>
      <p>Dear Sir/Madam,</p>
      <br />
      <p>
        <b>
          COMPLETION OF INTERNSHIP: {formData.courtesyTitle}{" "}
          {formData.name.toUpperCase()}
        </b>
      </p>
      <p>
        This is to certify that {formData.courtesyTitle} {formData.name}, an
        undergraduate of University of {formData.university}, successfully
        completed the Internship program offered by Hasthiya (HIT) from{" "}
        {formData.startDate} to {formData.endDate}. After the internship period,
        she/he worked as an {formData.jobType} from {formData.startDate} to{" "}
        {formData.endDate}.
      </p>
      <p>
        Given below is a brief overview of {formData.name}'s duties and
        responsibilities during {formData.pronoun} internship period and {formData.pronoun} time as an
        {formData.jobType} at HIT:
      </p>
      <ul>
        <li>{formData.work1}</li>
        <li>{formData.work2}</li>
        <li>{formData.work3}</li>
        <li>{formData.work4}</li>
        <li>{formData.work5}</li>
      </ul>
      <p>
        We thank {formData.courtesyTitle} {formData.name} for the services
        rendered to the company and wish {formData.pronoun} every success in {formData.pronoun} future
        endeavors.
      </p>
      <br />
      <br />
      <p>.........................................</p>
      <p>Yours Faithfully,</p>
      <p>
        <b>Mr. H. M. R. U. K. Bandara</b>
      </p>
      <p>
        <b>Chief Executive Officer (Hasthiya IT)</b>
      </p>
      <img
        src="bottom-corner-image.png"
        alt="Bottom Corner"
        className="corner-image bottom-corner"
      />
      <img
        src="right-bottom-corner-image.png"
        alt="Right Bottom Corner"
        className="corner-image right-bottom-corner"
      />
    </div>
  );
});

export default ServiceLetter;
